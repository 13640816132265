<template>
  <div>
    <Panel>
      <template #header>
        <h1 class="text-5xl font-light">{{ i18n.$t("inventories") }}</h1>
      </template>

      <!-- <Message v-if="!!hasError" severity="error" :closable="false">{{ hasError }}</Message> -->

      <Sidebar
        v-model:visible="visibleRight"
        class="p-sidebar-md"
        position="right"
      >
        <div v-if="currentMaterial">
          <div class="flex flex-column m-3 mb-5">
            <Image
              :src="getPreview(currentMaterial.Preview)"
              alt="Image"
              preview
            />
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon
                icon="akar-icons:link-chain"
                class="mr-3"
                width="24px"
                height="24px"
              />
              <div class="flex flex-row align-items-center">
                <h6
                  class="text-lg mb-0 truncate"
                  target="_blank"
                  :href="currentMaterial.Link"
                >
                  {{ currentMaterial.Name }}
                </h6>
                <br />
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon icon="bi:box" class="mr-3" width="24px" height="24px" />
              <div>
                <span v-for="mood in currentMaterial.Moods" v-bind:key="mood">
                  <Chip :label="mood.Moods_id.Name" class="mr-2"></Chip>
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex flex-row align-items-center flex-wrap">
              <Icon
                icon="entypo:price-tag"
                class="mr-3"
                width="24px"
                height="24px"
              />
              <div>
                <span class="text-base mb-1"
                  >{{ parseFloat(currentMaterial.Price).toFixed(2) }} €</span
                >
              </div>
            </div>
          </div>
          <div class="flex flex-column ml-5 mb-4">
            <div class="flex align-self-center">
              <img :src="getPreview(currentMaterial.Partner.Logo)" />
            </div>
          </div>
        </div>
      </Sidebar>

      <DataTable
        v-if="materials"
        :value="materials"
        :paginator="true"
        class="p-datatable-materials z-4"
        :rows="20"
        dataKey="id"
        :rowHover="true"
        v-model:selection="selectedMaterial"
        v-model:filters="filters"
        filterDisplay="menu"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[20, 35, 50]"
        :currentPageReportTemplate="
          i18n.$t('d_padination', {
            first: first,
            last: last,
            totalRecords: totalRecords,
          })
        "
        :globalFilterFields="['Name', 'LatinName', 'BlossomPeriod']"
        responsiveLayout="scroll"
        @row-click="getDetail($event)"
      >
        <template #header>
          <div class="flex justify-content-between align-items-center">
            <h5 class="m-0">{{ i18n.$t("materials") }}</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="searchValue"
                v-on:change="globalSearch"
                :placeholder="i18n.$t('search')"
              />
            </span>
          </div>
        </template>
        <template #empty> No Material found. </template>
        <template #loading> Loading materials data. Please wait. </template>
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column
          field="Reference"
          :header="i18n.$t('reference')"
          sortable
          filterMatchMode="contains"
          style="min-width: 6rem"
        >
          <template #body="{ data }">
            <span class="text-base">{{ data.Reference }}</span>
          </template>
        </Column>

        <Column
          field="Name"
          :header="i18n.$t('name')"
          style="min-width: 25rem"
          sortable
        >
          <template #body="{ data }">
            <div class="flex align-items-center">
              <img class="avatar-img mr-3" :src="getPreview(data.Preview)" />
              <span class="text-base">{{ data.Name }}</span>
            </div>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>

        <Column
          header="Moods"
          filterField="mood.Moods_id.Name"
          :showFilterMatchModes="true"
          :filterMenuStyle="{ width: '10rem' }"
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            <span v-for="mood in data.Moods" v-bind:key="mood">
              <Chip
                v-if="mood.Moods_id"
                :label="mood.Moods_id.Name"
                class="mr-2 my-2"
              ></Chip>
            </span>
          </template>
          <template #filter="{ filterModel }">
            <Dropdown
              v-model="filterModel.value"
              :options="moods"
              placeholder="Any"
              class="p-column-filter"
              :showClear="true"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value">
                  <Chip
                    :label="slotProps.value.en"
                    v-if="i18n.locale.value === 'en'"
                  ></Chip>
                  <Chip
                    v-else
                    :label="slotProps.value.fr"
                    class="mr-2 my-2"
                  ></Chip>
                </span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span v-if="slotProps.option">
                  <Chip
                    :label="slotProps.option.en"
                    v-if="i18n.locale.option === 'en'"
                    class="mr-2 my-2"
                  ></Chip>
                  <Chip
                    v-else
                    :label="slotProps.option.fr"
                    class="mr-2 my-2"
                  ></Chip>
                </span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column header="Price" sortable="true" style="min-width: 14rem">
          <template #body="{ data }">
            <span v-if="data.Price"
              >{{ parseFloat(data.Price).toFixed(2) }}€</span
            >
          </template>
        </Column>
      </DataTable>
    </Panel>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import router from "@/router";
import { useProcess } from "../../compositions/useProcess";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useMaterialService } from "../../compositions/services/useMaterialService";
import { useProjectService } from "../../compositions/services/useProjectService";
import { useI18n } from "../../../src/i18nPlugin";
import usePreview from "../../compositions/usePreview";
import { useToast } from "primevue/usetoast";

export default {
  setup() {
    const { getPreview } = usePreview();
    const toast = useToast();
    const { getAllMaterial, getValueSearchMaterial, getMoodById, materials } =
      useMaterialService();
    const { moods, getMoods } = useProjectService();
    const currentMaterial = ref();
    const searchValue = ref();
    const selectedMaterial = ref();
    const visibleRight = ref(false);
    // const plantService = new PlantService();
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "mood.Moods_id.Name": {
        operator: FilterOperator.CONTAINS,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    const loading = ref(true);
    const i18n = useI18n();

    getAllMaterial(searchValue.value)
      .then(() => {})
      .catch((err) => {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: "3000",
        });
      })
      .finally(() => {
        loading.value = false;
      });
    getMoods();
    function isRunningRow(data) {
      if (data.hasError) {
        return "has-error";
      }
      loading.value = false;
      return data.state === "running" ? "is-running" : null;
    }

    function globalSearch() {
      loading.value = false;
      getValueSearchMaterial(searchValue.value)
        .then(() => {
          router.push(`?search=${searchValue.value}`);
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: "3000",
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }
    function getMood(value) {
      let response = "abc";
      getMoodById(value)
        .then((data) => {
          response = data.data.Name;
          return response;
        })
        .catch(() => {
          return "abc";
        });
    }
    function getDetail(event) {
      visibleRight.value = true;
      currentMaterial.value = event.data;
    }

    return {
      i18n,
      isRunningRow,
      globalSearch,
      ...useProcess(),
      getMood,
      materials,
      searchValue,
      currentMaterial,
      filters,
      loading,
      getPreview,
      getDetail,
      visibleRight,
      selectedMaterial,
      moods,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.is-running) {
  background-color: rgba(19, 160, 0, 0.15) !important;
}
::v-deep(.has-error) {
  background-color: rgba(160, 0, 0, 0.137) !important;
}

.truncate {
  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

img {
  vertical-align: middle;
}
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

.avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
}

::v-deep(.p-image) {
  img {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
  }
  .p-image-preview-indicator {
    height: 200px;
    border-radius: 8px;
  }
}

::v-deep(.p-datatable.p-datatable-materials) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
    position: relative;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
